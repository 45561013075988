<template>
    <div class="CalibrationReports full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p>{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-8"
                                id="pathmodal-list"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''" @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-small noBackButton/>
            <div class="content-area pa-8 flex-grow-1 bg-white">
                <v-row class="d-flex align-center mb-5 filter">
                    <v-col cols="5" class="pl-0 d-flex align-center">
                        <p>{{ $t('adjCal.type') }}:</p>
                        <v-select :items="filterType" outlined hide-details="auto" v-model="filterStatusSelected" attach class="ml-6"/>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="tableHeaders"
                    :items="filteredList"
                    sortBy='dateTime'
                    sort-desc
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" :id="item.id" :class="{'selected': selectedId.includes(item.id)}" @click="select(item.id)">
                            <td>{{ findType(item.calibrationType) }}</td>
                            <td v-if="item.calibrationType == 'basketPosition'">{{ item.tubeCount }} <span>{{ $t('general.tubes') }}</span></td>
                            <td v-else-if="item.calibrationType == 'frequency'"><span>–</span></td>
                            <td v-else>{{ item.basket }}</td>
                            <td>{{ findUser(item.user) }}</td>
                            <td>{{ item.dateTime }}</td>
                            <td><div class="bullet" :class="{'connected': item.status}"></div></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToCalibration"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button button-function="export" :successCheck="showExportSuccess" :progress="showExportProgress" :class="{'disabled': selectedId.length == 0 || !checkUserRights('adjCalExport')}" @footerButtonClick="pathmodal = true"></footer-button>
            <footer-button button-function="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" :class="{'disabled': selectedId.length == 0 || !checkUserRights('adjCalPrint')}" @footerButtonClick="printReport"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'CalibrationReports',
    components: {
        NavigationLeftSmall,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            selectedId: [],
            filterStatusSelected: 'show all',
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
        }
    },
    computed: {
        ...mapState([
            'calibrationlist',
            'userlist',
            'pathlist',
            'activeStationId'
        ]),
        tableHeaders(){
            return [
                { text: this.$t('adjCal.type'), align: 'start', value: 'type' },
                { text: this.$t('adjCal.basket'), align: 'start', value: 'basket' },
                { text: this.$t('adjCal.performedBy'), value: 'user' },
                { text: this.$t('general.date'), value: 'dateTime' },
                { text: this.$t('general.status'), align: 'center', value: 'status', sortable: false},
            ]
        },
        filterType(){
            return [
                this.$t('filter.showAll'),
                this.$t('filter.height'),
                this.$t('filter.temperature'),
                this.$t('filter.basketPosition'),
                this.$t('filter.frequency'),
            ]
        },
        filteredList(){
            let filteredlist = this.$store.state.calibrationlist;
            if (this.filterStatusSelected == this.$t('filter.height')){
                filteredlist = filteredlist.filter(function(calibration){
                    return calibration.calibrationType == 'height';
                })
            } else if (this.filterStatusSelected == this.$t('filter.temperature')){
                filteredlist = filteredlist.filter(function(calibration){
                    return calibration.calibrationType == 'temperature';
                })
            } else if (this.filterStatusSelected == this.$t('filter.basketPosition')){
                filteredlist = filteredlist.filter(function(calibration){
                    return calibration.calibrationType == 'basketPosition';
                })
            } else if (this.filterStatusSelected == this.$t('filter.frequency')){
                filteredlist = filteredlist.filter(function(calibration){
                    return calibration.calibrationType == 'frequency';
                })
            }
            return filteredlist;
        }
    },
    methods: {
        goToCalibration(){
            this.$router.push('calibration');
        },
        findUser(userID){
            let userName = this.userlist.find(function(user){
                return user.id == userID;
            });
            if (userName){
                userName = userName.username;
            } else {
                userName = this.$t('general.unknown');
            }
            return userName;
        },
        findType(type){
            if (type == 'height'){
                return this.$t('adjCal.height');
            } else if (type == 'temperature'){
                return this.$t('adjCal.temperature');
            } else if (type == 'basketPosition'){
                return this.$t('adjCal.basketPosition');
            } else if (type == 'frequency'){
                return this.$t('adjCal.frequency');
            } else {
                return this.$t('general.unknown');
            }
        },
        select(id){
            let contains = this.selectedId.find(function(item){
                return item == id;
            });
            if (contains){
                let removed = [];
                this.selectedId.forEach(selection =>{
                    if (selection != id){
                        removed.push(selection);
                    }
                })
                this.selectedId = removed;
            } else {
                this.selectedId.push(id);
            }
        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/export', {'calibrationId': this.selectedId, 'pathId': this.exportPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
        printReport(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/print', {'calibrationId': this.selectedId}])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        }
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/user/list', 'userlist']);
        this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrate/list', {id: this.activeStationId}, 'calibrationlist']);

        this.$watch('$store.state.activeStationId', function() {
            this.$store.dispatch('postAxiosSetter', ['/disi/calibration/calibrate/list', {id: this.activeStationId}, 'calibrationlist']);
            this.selectedId = [];
        });
    },
}
</script>
<style scoped lang="scss">
.CalibrationReports .theme--light.v-data-table .v-data-table__wrapper > table tbody tr.selected{
    background-color: $disiIrisblue!important;
    td{
        color: white;
    }
}
.bullet{
    display: flex;
    align-items: center;
    justify-content: center;
    &::before{
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $disiRed;
    }
    &.connected::before{
        background-color: $disiIrisblue;
    }
}
.selected{
    .bullet{
        &::before{
            width: 28px;
            height: 28px;
            border: 2px solid $disiBluegreyLight;
        }
    }
}
</style>